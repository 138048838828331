<template>
    <v-container grid-list-xl fluid>
        <h1>Relatório de Carregamentos</h1>
        <v-divider class="mb-4" />
        <v-layout wrap>
            <v-flex xs12 sm12>
                <DateInterval @onChange="change" />
            </v-flex>
        </v-layout>
        <v-layout wrap>
            <v-flex xs12 sm6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>battery_charging_full</v-icon>
                            {{ total }} kWh
                        </div>
                        Energia consumida
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>score</v-icon>
                            {{ items.length }}
                        </div>
                        Número de carregamentos
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 sm12 v-if="items.length == 0">
                <v-alert type="error"> Nenhum dado encontrado para esse perído de tempo. </v-alert>
            </v-flex>
            <v-flex xs12 v-if="items.length > 0">
                <v-flex v-if="items.length > 0" xs12 sm6 offset-sm6>
                    <v-text-field label="Buscar" v-model="filtro" />
                </v-flex>
                <v-data-table :headers="headers" :items="listWithFilter" :items-per-page="-1" class="elevation-2" hide-default-footer>
                    <v-progress-linear v-slot:progress color="blue" indeterminate />
                    <template v-slot:item.car.consumption="{ item }">{{ item.car.consumption }} kWh</template>
                    <template v-slot:item.car.minutesConnected="{ item }">{{ dateTime.tranformTime(item.car.minutesConnected) }}</template>
                    <template v-slot:item.car.minutesCharger="{ item }">{{ dateTime.tranformTime(item.car.minutesCharger) }}</template>
                    <template v-slot:item.paymentValue="{ item }">
                        <v-chip v-if="item.paymentValue > 0" class="ma-2" color="success" text-color="white">
                            <v-avatar left> <v-icon>done</v-icon> </v-avatar>Pago R${{ item.paymentValue.toFixed(2) }}
                        </v-chip>
                        <v-chip v-else-if="item.paymentValue == 0" class="ma-2" color="grey" text-color="white">
                            <v-avatar left> <v-icon>grade</v-icon> </v-avatar>Grátis
                        </v-chip>
                        <v-chip v-else class="ma-2" color="error" text-color="white">
                            <v-avatar left> <v-icon>error</v-icon> </v-avatar>Pendente
                        </v-chip>
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import dateTime from "@/helpers/DateTime";
import DateInterval from "@/components/core/DateInterval";

export default {
    components: {
        DateInterval,
    },

    data() {
        return {
            dateTime,
            filtro: null,
            headers: [
                { text: "Consumo", value: "car.consumption", align: "right" },
                { text: "Tempo Conectado", value: "car.minutesConnected", align: "right" },
                { text: "Tempo Carregando", value: "car.minutesCharger", align: "right" },
                { text: "Data", value: "dateTime", align: "right" },
                { text: "Status Pagamento", value: "paymentValue", align: "center" },
                { text: "Carregador", align: "left", value: "charger.name", visibility: false },
                { text: "Local", align: "left", value: "charger.point.name", visibility: false },
            ],
            items: [],
        };
    },

    methods: {
        change(dates) {
            let query = dates.length == 2 ? `?dateFrom=${dates[0]}&dateTo=${dates[1]}` : `?dateFrom=${dates[0]}&dateTo=${dates[0]}`;
            this.$http
                .get("api/v2/charge/" + this.$store.state.user.ID + query)
                .then((result) => {
                    this.items = result;
                })
                .catch(() => {
                    this.items = [];
                });
        },
    },

    computed: {
        listWithFilter() {
            if (this.filtro) {
                let exp = new RegExp(this.filtro.trim(), "i");
                return this.items.filter((item) => exp.test(item.pointName) || exp.test(item.chargerName));
            } else {
                return this.items;
            }
        },
        quality() {
            return (q) => (q == 0 ? `success` : `error`);
        },
        total() {
            return Number.parseFloat(
                this.items.reduce(function (sum, i) {
                    return sum + i.car.consumption;
                }, 0)
            ).toFixed(2);
        },
    },
};
</script>